const { Entity } = require('./entity')

class Customer extends Entity {
  constructor (quickbooks) {
    super(quickbooks, 'Customer')
  }
}

module.exports = {
  Customer
}
