
import storage from 'redux-persist/lib/storage'
import get from 'lodash/get'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

import getApplicationConfig from '../common/config'
import { isPlannerUser, isPSMDriver, isPSMFacility } from '../subapps/shipments/common/utils'
import { userIsAuthorised } from '../van-shared/utils/auth'
import { hasFeature } from '../van-shared/utils/features'
import { isLeadBeforeSync } from '../common/utils'

// IMPORT COMMON REDUCERS
import userSession from './reducers/common/userSession'
import { syncReducer as sync } from './reducers/common/sync'
import location from './reducers/common/location'
import featureOnboarding from './reducers/common/featureOnboarding'

// IMPORT RETAILER REDUCERS
import heldLedger from './reducers/retailer/heldLedger'
import subscriptions from './reducers/retailer/subscriptions'
import orderPlacement from './reducers/retailer/orderPlacement'
import fastOrderPlacement from './reducers/retailer/fastOrderPlacement'
import topups from './reducers/retailer/topups'
import leadExperience from './reducers/retailer/leadExperience'
import finances from './reducers/retailer/finances'

// IMPORT SHIPMENTS REDUCERS
import batches from './reducers/shipments/batches'
import planning from './reducers/shipments/planning'
import pickList from './reducers/shipments/pick-list'
import shipments from './reducers/shipments'
import geolocation from './reducers/shipments/geolocation'
import masterDataShipments from './reducers/shipments/master-data'

// IMPORT REPORTS REDUCERS
import masterDataReports from './reducers/reports/master-data'
import reportEntry from './reducers/reports/report-entry'
import overview from './reducers/reports/overview'

// IMPORT ANALYTICS REDUCERS
import masterDataAnalytics from './reducers/analytics/master-data'

const hasShipmentReducers = (user) => (
  userIsAuthorised(user, 'feature:shipments') || userIsAuthorised(user, 'feature:lastMileDeliveries')
)

// if you want to persist this part of the state
// i.e. Save on the browser's local storage
const allowList = [
  'location',
  'heldLedger',
  'orderPlacement',
  'fastOrderPlacement',
  'topups',
  'leadExperience',
  'finances',
  'geolocation'
]

// This ensures your redux state is saved to persisted storage whenever it changes
// we pass this to the store
const persistConfig = {
  key: 'root',
  version: 1,
  stateReconciler: autoMergeLevel1,
  whitelist: allowList,
  storage
}

const getUserReducers = (user) => {
  const config = getApplicationConfig(window.location.href)

  const commonReducers = {
    userSession,
    sync,
    location,
    featureOnboarding
  }

  if (!user || !config) {
    return {
      reducers: commonReducers,
      persistConfig
    }
  }

  // SL
  const isPlanner = isPlannerUser(user)
  const isRetailer = userIsAuthorised(user, 'feature:userRole:pharmacyUser')
  const isLeadUserBeforeSync = isLeadBeforeSync(user)

  // PSM
  const isPSMFacilityUser = isPSMFacility(user)
  const isPSMDriverUser = isPSMDriver(user)

  // Find out if analytics access is enabled for this user
  // Is it active for the tenant?
  let hasAnalytics = hasFeature(config.features, 'analytics')
  // Is the user excluded from analytics?
  if (hasAnalytics) {
    const nonAnalyticsRoles = get(config, 'features.analytics.nonAnalyticsRoles', [])
    hasAnalytics = !(user.roles && user.roles.some(role => nonAnalyticsRoles.includes(role)))
  }

  // REDUCER ACCESS
  const withRetailerReducers = isRetailer || isLeadUserBeforeSync
  const withOptionalRetailerReducers = withRetailerReducers || isPlanner
  const withAnalyticsReducers = hasAnalytics
  const withShipmentReducers = hasShipmentReducers(user)

  // REPORTS
  // Adding reporting here because almost all users need it including retailers
  const reports = {
    masterDataReports,
    reportEntry,
    overview
  }

  let userReducers = {
    ...commonReducers,
    ...reports
  }

  // RETAILERS
  if (withRetailerReducers) {
    userReducers = {
      ...userReducers,
      heldLedger,
      orderPlacement,
      fastOrderPlacement,
      topups,
      leadExperience,
      finances
    }
  }

  // PLANNERS & RETAILERS
  if (withOptionalRetailerReducers) {
    userReducers = {
      ...userReducers,
      subscriptions
    }
  }

  // SHIPMENTS
  if (withShipmentReducers) {
    userReducers = {
      ...userReducers,
      batches,
      planning,
      pickList,
      shipments,
      masterDataShipments,
      geolocation
    }
  }

  // ANALYTICS
  if (withAnalyticsReducers) {
    userReducers = {
      ...userReducers,
      masterDataAnalytics
    }
  }

  // We only want to persist shipments for PSM facility and driver users
  if (isPSMFacilityUser || isPSMDriverUser) {
    persistConfig.whitelist.push('shipments')
  }

  return {
    reducers: userReducers,
    persistConfig
  }
}

export {
  getUserReducers
}
