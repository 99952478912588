import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { Loading } from '@fielded/shared-ui'
import { getShipmentBatches } from '@fielded/fs-api/lib/shipment/utils/utils'

import { withApi } from '../../../../../common/ApiProvider'
import withConfig from '../../../../../van-shared/hoc/withConfig'
import { withUser } from '../../../../../common/AuthenticationProvider'
import withShipment from '../../../common/WithShipmentWrapper'
import { fetchMasterData, selectMasterData } from '../../../../../root/reducers/shipments/master-data'
import { onClearGeolocation, selectGeolocation } from '../../../../../root/reducers/shipments/geolocation'
import { clearGeolocationWatch, getGeolocation } from '../../../../../utils/geolocation'
import { getPermission, PERMISSION_STATES, PERMISSIONS } from '../../../../../utils/permissions-service'
import FacilityDeliveryDetail from './FacilityDeliveryDetail'
import { isEPNFacility, isShipmentComplete } from '../../../common/utils'

const FacilityDeliveryDetailContainer = ({
  api,
  user,
  config,
  history,
  shipment: delivery
}) => {
  const dispatch = useDispatch()
  const masterData = useSelector(selectMasterData)
  const geolocationData = useSelector(selectGeolocation)

  const [otp, setOtp] = useState()
  const [locationObject, setLocationObject] = useState()
  const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false)
  const [distance, setDistance] = useState()
  const [loading, setLoading] = useState(true)
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)
  const [isRequestingPermission, setIsRequestingPermission] = useState(false)

  const {
    geoPosition,
    position,
    newPermission,
    positionErrorMsg,
    watchId
  } = geolocationData

  const isEPNFacilityUser = isEPNFacility(config, user)
  const isDeliveryReceived = isShipmentComplete(delivery)

  const hasReceivedMasterData = get(masterData, 'hasReceivedMasterData', false)
  const otpVerified = get(delivery, 'otp.hasKeyAndPassword', false)
  const otpKey = get(delivery, 'otp.key', { version: 'v1' })

  const skipGPSValidation = get(delivery, 'isGpsAuthenticated', false) || !!(get(delivery, 'gpsValidationFailComment.reason', false))
  const skipGPSAndOTPValidation = !isDeliveryReceived && skipGPSValidation && otpVerified

  const initialize = async () => {
    try {
      if (!hasReceivedMasterData) {
        await dispatch(fetchMasterData())
      }

      const shipmentId = delivery.id
      const otp = await api.shipment.otp.rest.getPassword(shipmentId)
      const otpCode = otp ? otp.password : ''

      setOtp(otpCode)
      onHandleGetGeolocation()
    } catch (error) {
      console.error(error)
    }
  }

  const onOpenBottomDrawer = () => {
    setIsBottomDrawerOpen(true)
  }

  const onCloseBottomDrawer = () => {
    setIsBottomDrawerOpen(false)
  }

  const onOpenPermissionModal = () => {
    setIsPermissionModalOpen(true)
  }

  const onClosePermissionModal = () => {
    setIsRequestingPermission(false)
    setIsPermissionModalOpen(false)
  }

  const onRequestLocationPermission = async () => {
    setIsRequestingPermission(true)

    await onGetGeolocation(newPermission, locationObject)
    onClosePermissionModal()
  }

  const onClearGeolocationWatch = () => {
    if (watchId) {
      clearGeolocationWatch(watchId)
      dispatch(onClearGeolocation())
    }
  }

  const onHandleGetCurrentDistance = async () => {
    if (position.lat && position.lng) {
      await onGetCurrentDistance()
    }
  }

  const onGetCurrentDistance = async () => {
    // Using this as a fallback since some test shipments lack a location object
    // (locationObject = undefined) or have invalid latLng values ('null,null').
    const facilityCoordsMock = '6.4926317,3.489617'

    const facilityPosition = get(locationObject, 'additionalData.latLng', facilityCoordsMock)
    const [latPositionFacility, lngPositionFacility] = (
      facilityPosition === 'null,null' ? facilityCoordsMock.split(',') : facilityPosition.split(',')
    )

    try {
      const distance = await api.shipment.calculateDistance(
        { lat: position.lat, long: position.lng }, // from point A coordinates
        { lat: Number(latPositionFacility), long: Number(lngPositionFacility) } // to point B coordinates
      )

      setDistance(distance)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  const onGetGeolocation = async (permission, locationObject) => {
    const configGeolocationOptions = {
      maximumAge: 5 // five seconds
    }

    // We need to pass dispatch in order to update the redux store
    try {
      const result = await getGeolocation(permission, dispatch, configGeolocationOptions)

      // only watch position if permission is granted after initial prompt
      if (result.newPermission === PERMISSION_STATES.GRANTED && result.newPermission !== permission) {
        await getGeolocation(result.newPermission, dispatch, configGeolocationOptions)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
      if (permission === PERMISSION_STATES.PROMPT && !watchId) {
        onOpenBottomDrawer()
      }
    }
  }

  const onHandleGetGeolocation = async () => {
    const locationId = get(delivery, 'destination.id', '')
    let permission

    try {
      const locationObject = await api.location.get(locationId)
      permission = await getPermission(PERMISSIONS.GEOLOCATION)

      setLocationObject(locationObject)

      if (permission && permission !== PERMISSION_STATES.PROMPT) {
        await onGetGeolocation(permission, locationObject)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      if (permission === PERMISSION_STATES.PROMPT) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    onHandleGetCurrentDistance()
  }, [position.lat, position.lng])

  useEffect(() => {
    return () => onClearGeolocationWatch()
  }, [watchId])

  if (skipGPSAndOTPValidation) {
    const shipmentId = get(delivery, 'id')
    return <Redirect to={`/shipments/pick-list/${shipmentId}`} />
  }

  if (loading) {
    return <Loading loadingText='Loading delivery details ...' />
  }

  if (!masterData || !Object.keys(masterData).length || !delivery) {
    return <Redirect to='/shipments/facility/deliveries' />
  }

  const productsById = get(masterData, 'products.byId')
  const batches = getShipmentBatches(delivery, productsById)

  return (
    <FacilityDeliveryDetail
      otp={otp}
      history={history}
      delivery={delivery}
      products={batches}
      api={api}
      distance={distance}
      geoPosition={geoPosition}
      isBottomDrawerOpen={isBottomDrawerOpen}
      isPermissionModalOpen={isPermissionModalOpen}
      isRequestingPermission={isRequestingPermission}
      locationObject={locationObject}
      onCloseBottomDrawer={onCloseBottomDrawer}
      onClosePermissionModal={onClosePermissionModal}
      onOpenBottomDrawer={onOpenBottomDrawer}
      onOpenPermissionModal={onOpenPermissionModal}
      onRequestLocationPermission={onRequestLocationPermission}
      otpKey={otpKey}
      permission={newPermission}
      position={position}
      positionErrorMsg={positionErrorMsg}
      skipGPSValidation={skipGPSValidation}
      isEPNFacilityUser={isEPNFacilityUser}
      isDeliveryReceived={isDeliveryReceived}
    />
  )
}

const withHOCs = flowRight(
  withApi,
  withConfig,
  withUser,
  withShipment
)

export default withHOCs(FacilityDeliveryDetailContainer)
