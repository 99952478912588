const {
  addDays,
  setISOWeek,
  setISOYear,
  setYear,
  subWeeks,
  addWeeks,
  addMonths,
  setMonth,
  subMonths,
  format,
  endOfMonth
} = require('date-fns')

const { periodIdToDate } = require('../report/tools/ids')
const { addTZ, subTZ } = require('../report/tools/tz')
const getPeriod = require('../report/tools/get-period')

// ISO year for weeks, not calendar year
const weeklyReportingPeriodFormat = 'GGGG[-W]WW'
// Calendar year, not iso year
const bimonthlyReportingPeriodFormat = 'YYYY[-M]MM'

const WEEKLY_PERIOD = {
  'periodType': {
    'unit': 'week',
    'quantity': 1
  },
  'entryPeriodRule': 'current-period'
}

const BIMONTH_PERIOD = {
  'periodType': {
    'unit': 'month',
    'quantity': 2
  },
  'entryPeriodRule': 'next-period'
}

const MONTH_PERIOD = {
  'periodType': {
    'unit': 'month',
    'quantity': 1
  },
  'entryPeriodRule': 'current-period'
}

exports.dateToWeeklyReportingPeriod = function dateToWeeklyReportingPeriod (periodType, date = new Date().toISOString()) {
  switch (periodType) {
    case 'weekly':
      if (date.includes('-W')) {
        // date is already a reporting period
        return date
      }

      return format(date, weeklyReportingPeriodFormat)
    default:
      throw new Error('Unsupported reporting period type: ' + periodType)
  }
}

exports.dateToBimonthlyReportingPeriod = function dateToBimonthlyReportingPeriod (date = new Date().toISOString()) {
  return 'bimonth:' + format(date, bimonthlyReportingPeriodFormat)
}

exports.previousReportingPeriod = function previousReportingPeriod (periodType, period = null) {
  // Here we need the period formats
  switch (periodType) {
    case 'weekly': {
      period = period || getPeriod(WEEKLY_PERIOD, new Date().toISOString(), true).id
      const [year, week] = period.split('-W')
      let date = setISOWeek(new Date(), parseInt(week, 10))
      date = setISOYear(date, parseInt(year, 10))
      const prevWeek = subWeeks(date, 1)
      return format(prevWeek, weeklyReportingPeriodFormat)
    }
    case 'bimonthly': {
      period = period || getPeriod(BIMONTH_PERIOD, new Date().toISOString(), true).id
      const [year, month] = period.split('-M')
      let date = setMonth(new Date(), parseInt(month, 10) - 1)
      date = setYear(date, parseInt(year, 10))
      const prevBiMonth = subMonths(date, 2)
      return format(prevBiMonth, bimonthlyReportingPeriodFormat)
    }
    case 'monthly': {
      period = period || getPeriod(MONTH_PERIOD, new Date().toISOString(), true).id
      const [year, month] = period.split('-M')
      let date = setMonth(new Date(), parseInt(month, 10) - 1)
      date = setYear(date, parseInt(year, 10))
      const prevMonth = subMonths(date, 1)
      return format(prevMonth, bimonthlyReportingPeriodFormat)
    }
    default:
      throw new Error('Unknown reporting period type: ' + periodType)
  }
}

exports.nextReportingPeriod = function nextReportingPeriod (periodType, period = null) {
  switch (periodType) {
    case 'weekly': {
      period = period || getPeriod(WEEKLY_PERIOD, new Date().toISOString(), true).id
      const [year, week] = period.split('-W')
      let date = setISOWeek(new Date(), parseInt(week, 10))
      date = setISOYear(date, parseInt(year, 10))
      const nextWeek = addWeeks(date, 1)
      return format(nextWeek, weeklyReportingPeriodFormat)
    }
    case 'bimonthly': {
      period = period || getPeriod(BIMONTH_PERIOD, new Date().toISOString(), true).id
      const [year, month] = period.split('-M')
      let date = setMonth(new Date(), parseInt(month - 1, 10))
      date = setYear(date, parseInt(year, 10))
      const nextBiMonth = addMonths(date, 2)
      return format(nextBiMonth, bimonthlyReportingPeriodFormat)
    }
    case 'monthly': {
      period = period || getPeriod(MONTH_PERIOD, new Date().toISOString(), true).id
      const [year, month] = period.split('-M')
      let date = setMonth(new Date(), parseInt(month - 1, 10))
      date = setYear(date, parseInt(year, 10))
      const nextMonth = addMonths(date, 1)
      return format(nextMonth, bimonthlyReportingPeriodFormat)
    }
    default:
      throw new Error('Unknown reporting period type: ' + periodType)
  }
}

exports.reportingPeriodToDate = function reportingPeriodToDate (reportPeriod) {
  return periodIdToDate(reportPeriod, {compatMode: true})
}

exports.endOfReportingPeriodToDate = function endOfReportingPeriod (reportPeriod) {
  const [, period] = reportPeriod.split(/-(W|M)/)
  if (!(period === 'W' || period === 'M')) {
    throw new Error('Unknown reporting period type for date: ' + reportPeriod)
  }
  const date = subTZ(exports.reportingPeriodToDate(reportPeriod))

  let endDate = date
  if (period === 'W') {
    // Weekly reports can be submitted on sundays
    endDate = addDays(date, 2)
  }

  if (period === 'M') {
    endDate = addMonths(date, 1)
    endDate = endOfMonth(endDate)
  }

  return addTZ(endDate).toJSON().split('T')[0]
}

exports.sortByLatestReport = (a, b) => {
  return (a.submittedAt < b.submittedAt) ? -1 : ((a.submittedAt > b.submittedAt) ? 1 : 0)
}
