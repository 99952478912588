const { Entity } = require('./entity')

class Payment extends Entity {
  constructor (quickbooks) {
    super(quickbooks, 'Payment')
  }

  async create ({
    payment: {
      totalAmount,
      companyCode,
      customerId
    },
    lines
  }) {
    const qboPayment = {
      TotalAmt: totalAmount,
      CustomerRef: {
        value: customerId
      },
      Line: lines.map(({ type, id, amount }) => ({
        Amount: amount,
        LinkedTxn: [
          {
            TxnType: type,
            TxnId: id
          }
        ]
      }))
    }

    const { Payment: createdPayment } = await this.quickbooks.quickbooksInterface.post(
      companyCode,
      `payment?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`,
      qboPayment
    )
    return createdPayment
  }
}

module.exports = {
  Payment
}
