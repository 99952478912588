const { Entity } = require('./entity')
const { createLines } = require('./lines')
const { getTax } = require('./tax')

class CreditMemo extends Entity {
  constructor (quickbooks) {
    super(quickbooks, 'CreditMemo')
  }

  async create ({
    creditMemo: {
      docNumber,
      vatRate,
      email,
      companyCode,
      customerId
    },
    lines,
    hasCustomVatLine = false
  }) {
    const qboLines = await createLines({
      quickbooks: this.quickbooks,
      companyCode,
      lines,
      hasCustomVatLine,
      vatRate
    })

    const qboCreditMemo = {
      CustomerRef: {
        value: customerId
      },
      DocNumber: docNumber,
      BillEmail: {
        Address: email
      },
      Line: qboLines
    }

    if (!hasCustomVatLine) {
      const qboTax = await getTax({
        quickbooksInterface: this.quickbooks.quickbooksInterface,
        companyCode
      })
      qboCreditMemo.TxnTaxDetail = {
        TxnTaxCodeRef: {
          value: qboTax.codeId
        },
        TaxLine: [
          {
            DetailType: 'TaxLineDetail',
            TaxLineDetail: {
              TaxRateRef: {
                value: qboTax.rateId
              }
            }
          }
        ]
      }
    }

    if (qboLines.length === 0) {
      return null
    }

    const { CreditMemo: createdCreditMemo } = await this.quickbooks.quickbooksInterface.post(
      companyCode,
      `creditmemo?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`,
      qboCreditMemo
    )
    return createdCreditMemo
  }

  async sendEmail ({
    companyCode,
    creditMemo
  }) {
    const payload = {
      SyncToken: creditMemo.SyncToken,
      DeliveryAddress: {
        Address: creditMemo.BillEmail.Address
      }
    }
    const url = `creditmemo/${creditMemo.Id}/send?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    return this.quickbooks.quickbooksInterface.post(companyCode, url, payload)
  }
}

module.exports = {
  CreditMemo
}
