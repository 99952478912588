const { Entity } = require('./entity')

class Account extends Entity {
  constructor (quickbooks) {
    super(quickbooks, 'Account')
  }
}

module.exports = {
  Account
}
