import React from 'react'
import classnames from 'classnames'
import get from 'lodash/get'

import { ShelfLifeLogo } from '@fielded/shared-ui'

import withConfig from '../../../../van-shared/hoc/withConfig'
import { formatDate, isEpn, isShelflife } from '../../../../van-shared/utils/utils'

const NoteHeading = ({
  shipment,
  config
}) => {
  const isDistribution = shipment.statusType === 'distribution'
  const label = isDistribution ? 'Package' : 'Delivery'
  const isShelfLife = isShelflife(config)
  const logoUrl = isDistribution && isEpn(config) ? config.logoUrlDark : config.logoUrl

  const date = isDistribution
    ? formatDate(get(shipment, 'snapshotDates.sent', shipment.date), 'printLong')
    : formatDate(get(shipment, 'snapshotDates.received', shipment.date), 'printLong')

  return (
    <div className={classnames(
      'note-heading',
      {'note-heading--distribution': isDistribution}
    )}>
      {isShelfLife ? (
        <div className='note-heading__logo note-heading__logo--sl'>
          <ShelfLifeLogo />
        </div>
      ) : (
        <div className='note-heading__logo'>
          <img src={logoUrl} alt={`${config.name} Logo`} className='print-template__logo' />
        </div>
      )}
      <div className='note-heading__info'>
        {label} | {shipment.shipmentNo}
      </div>
      {shipment && shipment.patientName && <div className='note-heading__info'>
        Patient name: {shipment.patientName}
      </div>}
      {shipment && shipment.patientId && <div className='note-heading__info'>
        Patient ID: {shipment.patientId}
      </div>}
      <div className='note-heading__date'>
        {date}
      </div>
      {isShelfLife && (
        <div className='note-heading__sender'>
          www.shelflife.co
        </div>
      )}
    </div>
  )
}

export default withConfig(NoteHeading)
