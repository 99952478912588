import React from 'react'

import { BottomDrawer, PriceDisplay } from '@fielded/shared-ui'

const ShipmentContentsTablePSMPopup = ({
  product,
  country,
  onClose,
  withScrollableSection
}) => {
  if (!product) return null

  return (
    <BottomDrawer
      isOpen={!!product}
      onClose={onClose}
      customHeaderText='Batch details'
      withScrollableSection
    >
      <dl>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row vs-u-gap'>
          <dt className='shipment-contents-table__dt'>
            Name:
          </dt>
          <dd>{product.name}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Unit pack:
          </dt>
          <dd>{product.basicUnit}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Packed:
          </dt>
          <dd>{product.packed}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Delivered:
          </dt>
          <dd>{product.delivered}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Delivered:
          </dt>
          <dd>{product.delivered}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Manufacturer:
          </dt>
          <dd>{product.manufacturer}</dd>
        </div>
        <div className='vs-u-row-spaced shipment-contents-table__dl-row'>
          <dt className='shipment-contents-table__dt'>
            Unit price:
          </dt>
          <dd>
            <PriceDisplay
              value={product.price}
              roundUp={false}
              country={country}
              currencySymbol
            />
          </dd>
        </div>

        <div className='vs-u-row-spaced shipment-contents-table__dl-row shipment-contents-table__dl-row--sum'>
          <dt className='shipment-contents-table__dt'>
            Total price:
          </dt>
          <dd>
            <PriceDisplay
              value={product.deliveredValue}
              country={country}
              currencySymbol
              inColor
            />
          </dd>
        </div>
      </dl>
    </BottomDrawer>
  )
}

export default ShipmentContentsTablePSMPopup
