
const pendingPaymentsQuery = `
  with location_base as (
    select 
      uuid as location_id
    from avocado.data_location
    where uuid = $1 
  ),
  location_transactions as (
    select
      gen_random_uuid() as id,
      vt.location_id,
      vt.txn_id,
      vt.company_code,
      vt.payment_plan_id,
      vt.txn_type,
      vt.doc_number as quickbooks_doc_number,
      null as code,
      null as description,
      vt.due_date,
      vt.amount,
      vt.paid_amount
    from location_base lb
    cross join avocado.get_quickbookstransactions_by_location(lb.location_id) vt
  ),
  pending AS (
    SELECT
      p.id,
       p.data ->> 'locationId' as "location_id",
      due_date,
      p.amount,
      'pending invoice payment' AS txn_type,
      t.quickbooks_doc_number
    FROM location_base lb
    inner join avocado.data_paymentdebit p on p.data ->> 'locationId' = lb.location_id
    INNER join location_transactions t
      ON t.txn_type = 'payment'
     AND t.description ilike  '%' || ("data" -> 'remitaResponse' -> 'body' ->> 'transactionRef') || '%'
     AND t.location_id = p.data->>'locationId'
    WHERE p.data->'remitaResponse'->'body'->>'status' = 'Successful' AND t.id is null
    group by 1, 2, 3, 4, 5, 6

    UNION

    SELECT
      p.id,
      p.location_id,
      due_date,
      -- The amount from paystack is in the currencies subunit, convert to main unit
      case when p.total_amount > 0 then p.total_amount
      else p.amount_in_cents / 100  end as amount,
      'pending invoice payment' AS txn_type,
      quickbooks_doc_number
    FROM location_base lb
    inner join avocado.data_payment p on p.location_id = lb.location_id 
    LEFT JOIN location_transactions t
      ON t.txn_type = 'payment'
     AND t.description = "p"."payment_data" ->> 'reference'
     AND t.location_id = p.location_id
    WHERE (p.status = 'success' OR p.status = 'client_side_paid')
    and p.payment_method != 'credit' -- we need exclude credits from pending payments list
    and t.id IS NULL
    -- Payments made prior to the specified date should not be included in the pending payment logic.
    and p.created_at > '2023-06-08'
    group by 1, 2, 3, 4, 5, 6

    UNION

    SELECT
      p.id,
      lb.location_id,
      t.due_date,
      p.amount,
      'pending installment payment' AS txn_type,
      code AS quickbooks_doc_number
    FROM location_base lb
    inner join avocado.data_paymentdebit p on p.data->>'locationId' = lb.location_id
    INNER JOIN location_transactions t
      ON t.code = p.reference
     AND t.location_id = p.data->>'locationId'
     AND t.paid_amount < t.amount
    where t.txn_type = 'paym_plan' and (p.data->'remitaResponse'->'body'->>'status' = 'Successful'
    OR p.data -> 'paystackResponse' ->> 'status' = 'success')
    group by 1, 2, 3, 4, 5, 6

    UNION

    SELECT
      p.id,
      p.location_id,
      due_date,
      -- The amount from paystack is in the currencies subunit, convert to main unit
      p.amount_in_cents / 100 as amount,
      'pending invoice payment' AS txn_type,
      quickbooks_doc_number
    FROM location_base lb 
    inner join avocado.data_paygcreditpayment p on p.location_id = lb.location_id
    INNER join location_transactions t
       ON t.txn_id = p.qbo_txn_id
      AND t.company_code = p.qbo_company_code
      AND t.paid_amount < t.amount
    group by 1, 2, 3, 4, 5, 6
  )
`

const pendingPaymentBalanceQuery = `
  ${pendingPaymentsQuery}
  SELECT
    lb.location_id,
    coalesce(sum(amount), 0) as amount
  from location_base lb
  left join pending p on p.location_id = lb.location_id
  group by 1
`

module.exports = {
  pendingPaymentBalanceQuery
}
