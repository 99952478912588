
module.exports = `
  with base_location as (
    select 
      fsid,
      uuid as location_id,
      orders_status,
      loan_limit
    from avocado.data_location 
    where uuid = $1
  )
  select
    l.location_id,
    l.fsid,
    l.orders_status,
    l.loan_limit,
    total_balance as overall_balance,
    due_balance as overdue_balance
  from base_location l
  cross join avocado.get_quickbookstransactions_by_location(l.location_id) vt
  limit 1
`
