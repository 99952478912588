import React from 'react'
import PropTypes from 'prop-types'

import { Button, Page } from '@fielded/shared-ui'
import { hasFeature } from '../../../van-shared/utils/features'
import withConfig from '../../../van-shared/hoc/withConfig'

const Footer = ({onClickSave, onClickSubmit, loading, disabled, config}) => {
  const isSavingAllowed = hasFeature(config.features, 'stockReportingSave')

  return (
    <Page.Footer>
      <Button
        fill='outline'
        colorVariant='brand'
        size='large'
        onClick={onClickSubmit}
        id='footerSubmitBtn'
        className='submit-with-hint'
        loading={loading}
        disabled={disabled}
      >
        Submit stock count
      </Button>
      {isSavingAllowed && (
        <Button
          fill='outline'
          colorVariant='brand'
          size='large'
          onClick={onClickSave}
          id='footerSaveBtn'
          className='submit-with-hint'
          loading={loading}
          disabled={disabled}
        >
          Save
        </Button>
      )}
    </Page.Footer>
  )
}

Footer.propTypes = {
  onClickSubmit: PropTypes.func.isRequired
}

export default withConfig(Footer)
