import React, { Component } from 'react'

import { Loading } from '@fielded/shared-ui'
import { productAliasesByMarket, translateTerritoryAlias } from '@fielded/fs-api/lib/product/tools'
import { SHIPMENT_STATUS } from '@fielded/fs-api/lib/shipment/constants'
import { batchIdToProductId } from '@fielded/fs-api/lib/batch/tools'
import { getProductsForFollowUp } from '@fielded/fs-api/lib/shipment/tools'

import withConfig from '../../../van-shared/hoc/withConfig'
import withShipment from '../common/WithShipmentWrapper'
import { withUser } from '../../../common/AuthenticationProvider'
import { hasFeature } from '../../../van-shared/utils/features'
import { isReturnShipment } from '../../../common/utils/shipment'
import { isPsm } from '../../reports/common/utils'
import { isEPNFacility, isFpUser } from '../common/utils'

import Complete from './Complete'
import Partial from './Partial'

// TODO: this should be removed, if no longer needed
// adding this because some shipments on dev and staging
// don't have the new snapshot and this will break the workflow
export const getFirstSnapshot = (shipment) => {
  const snapShotDateList = Object.keys(shipment.snapshotDates)
  let originSnapshot = snapShotDateList[0]

  for (let i = 1; i <= snapShotDateList.length; i++) {
    if (shipment.snapshotDates[snapShotDateList[i]] <= shipment.snapshotDates[originSnapshot]) {
      originSnapshot = snapShotDateList[i]
    }
  }
  return originSnapshot
}
class CompleteContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      skipped: false,
      updatedNewSnapShot: null
    }
  }

  async componentDidMount () {
    const { api, shipment } = this.props
    let originLocation = await api.location.get(shipment.origin.id)
    let destinationLocation = await api.location.get(shipment.destination.id)
    if (!destinationLocation) {
      const locations = await api.location.getLocationsViaIds([shipment.destination.id])
      destinationLocation = locations[0]
    }
    if (!originLocation) {
      const locations = await api.location.getLocationsViaIds([shipment.origin.id])
      originLocation = locations[0]
    }

    const updatedNewSnapShot = await this.fetchProductAlias(destinationLocation)
    this.setState({
      isLoading: false,
      originLocation,
      destinationLocation,
      updatedNewSnapShot
    })
  }

  createResupplyOrder = async () => {
    this.setState({ confirmLoading: true })
    const { api, shipment, history } = this.props
    const response = await api.order.createFollowUpOrder(shipment)
    const groupId = response[0].groupId
    const programs = await api.program.list(true)
    const programId = programs[0].id
    this.setState({ confirmLoading: false })
    history.push(`/orders/${programId}/products-detail/${groupId}`)
  }

  appendResupplyShipment = async () => {
    this.setState({ confirmLoading: true })
    const { api, shipment, history } = this.props

    const productIds = Object.keys(shipment.counts).reduce((acc, batchId) => {
      return [...acc, batchIdToProductId(batchId)]
    }, [])

    const allProducts = await api.product.getProductsViaIds(productIds)

    const location = this.state.originLocation

    const { translatedCounts } = await api.shipment.bulkTranslateShipmentProducts({shipment, location, products: allProducts})

    const snapshot = await api.shipment.createFollowUpShipment(shipment, translatedCounts)

    this.setState({ confirmLoading: false })
    window.localStorage.setItem('newDelivery', JSON.stringify(snapshot))
    history.push(`/shipments/list`)
  }

  fetchProductAlias = async (destinationLocation) => {
    const { api, shipment } = this.props
    let productIds = []
    const firstSnapshot = getFirstSnapshot(shipment)
    const newSnapshot = Object.entries(shipment.history).find(([key]) => (
      key.includes(':status:new:') || key.includes(`:status:${firstSnapshot}:`)
    ))[1]
    if (!newSnapshot) {
      throw new Error('No new snapshot found in shipment history')
    }

    const destinationService = (destinationLocation && destinationLocation.services) ? destinationLocation.services[0] : null

    // Collect product IDs from the new snapshot
    if (shipment.status === SHIPMENT_STATUS.RECEIVED) {
      productIds = Object.keys(newSnapshot.counts).reduce((acc, batchId) => {
        return [...acc, batchIdToProductId(batchId)]
      }, [])

      if (productIds.length === 0) {
        throw new Error('No product IDs found')
      }

      try {
        const products = await api.product.getProductsViaIds(productIds)
        if (!products || products.length === 0) {
          throw new Error('No products returned from API')
        }

        // Create a mapping of product IDs to their market aliases
        const productsForTranslation = productAliasesByMarket(products)
        const productAliasMapping = {}

        productIds.forEach(p => {
          const product = batchIdToProductId(p)
          const alias = translateTerritoryAlias(product, productsForTranslation, destinationService)
          productAliasMapping[p] = alias
        })

        // Update the skus in newSnapshot with the market aliases. Because the new snapshots carry the central packpoint id and getProductsForFollowUp uses skus in both new and recieved snaps to find the diff
        const updatedCounts = Object.keys(newSnapshot.counts).reduce((acc, key) => {
          const productId = batchIdToProductId(key)
          const alias = productAliasMapping[productId] || productId
          const newKey = key.replace(productId, alias) // The newKey assignment replaces the original product ID in the batch ID (key) with its corresponding market alias.
          acc[newKey] = newSnapshot.counts[key]
          return acc
        }, {})

        // Return the updated newSnapshot with replaced keys
        return {
          ...newSnapshot,
          counts: updatedCounts
        }
      } catch (error) {
        console.error('Error fetching product aliases:', error)
        throw error
      }
    }
  }

  handleSkip = () => {
    this.setState({ skipped: true })
  }

  render () {
    const {
      config,
      shipment,
      user,
      location,
      rootHistory
    } = this.props

    const {
      isLoading,
      originLocation,
      destinationLocation,
      skipped,
      updatedNewSnapShot
    } = this.state

    if (isLoading) {
      return <Loading />
    }
    const isAdjustment = hasFeature(config.features, 'shipments.adjustments') && shipment.status === 'received'
    const negativeChanges = getProductsForFollowUp(shipment, updatedNewSnapShot)
    const isSupplierShipment = originLocation.level === 'supplier'
    const isPackPointShipment = originLocation.level === 'pack-point' && destinationLocation.level === 'pack-point'
    const isSDPShipment = destinationLocation.level === 'sdp' && originLocation.level === 'pack-point'
    const isSent = shipment.status === 'sent'
    const isReturn = isReturnShipment(user, shipment)
    const isFP = isFpUser(user)
    const isEPNFacilityUser = isEPNFacility(config, user)

    const isDeliveryPartiallyFulfilled = (
      !skipped && !!negativeChanges.length && !isFP && !isEPNFacilityUser && (isSupplierShipment || isSDPShipment || isPackPointShipment)
    )

    let pathName = `${location.pathname}/print/all-statements`
    let noteName = 'delivery note'
    if (isReturn) {
      pathName = `/shipments/returns-note/${shipment.id}`
      noteName = 'returns note'
    } else if (isSent) {
      noteName = 'package note'
    } else if (isPsm(config)) {
      pathName = `/shipments/last-mile-deliveries/delivery-statement/${shipment.snapshotId}`
    }

    return isDeliveryPartiallyFulfilled
      ? (
        <Partial
          isAdjustment={isAdjustment}
          createResupplyOrder={this.createResupplyOrder}
          appendResupplyShipment={this.appendResupplyShipment}
          negativeChanges={negativeChanges}
          onSkip={this.handleSkip}
        />
      )
      : (
        <Complete
          noteName={noteName}
          printPathname={pathName}
          isAdjustment={isAdjustment}
          rootHistory={rootHistory}
          isEPNFacilityUser={isEPNFacilityUser}
        />
      )
  }
}

export default withConfig(withUser(withShipment(CompleteContainer)))
