// api.payment is a namespace. It has only one table, paymentMethod
// available on api.payment.paymentMethod level.
// Plan is to add api.payment.debit table as well.

const TransactionsAdapter = require('./transactions-adapter')
const LoanLimitHistoryAdapter = require('./loan-limit-history-adapter')
const LateFeeInvoicingAdapter = require('./late-fee-invoicing-adapter')
const tools = require('./tools')

class FinancesApi {
  constructor (
    state,
    pgConnection,
    agaveAdapter,
    logger,
    quickbooks,
    location,
    dataShipment,
    creditApplication
  ) {
    const { user = {} } = state
    this.user = user
    const username = user.name
    this.agaveAdapter = agaveAdapter
    this.logger = logger
    if (pgConnection) {
      this.transactions = new TransactionsAdapter(
        pgConnection,
        username,
        dataShipment,
        creditApplication
      )
      this.loanLimitHistory = new LoanLimitHistoryAdapter(
        location,
        pgConnection,
        username
      )
      this.lateFeeInvoicing = new LateFeeInvoicingAdapter(
        pgConnection,
        username,
        logger
      )
    }
    this.quickbooks = quickbooks
    this.tools = tools
  }

  getTransactions (params, locationId) {
    return this.agaveAdapter.get(`finances/transactions`, {
      locationId: locationId || this.user.location.id,
      ...params
    })
  }

  getTransactionsBulk (params) {
    return this.agaveAdapter.post('finances/transactions/bulk', params)
  }

  getPinnedTransactions (locationId, params = {}) {
    return this.agaveAdapter.get(`finances/transactions/pinned`, {
      locationId: locationId || this.user.location.id,
      ...params
    })
  }

  getPayablesList (locationId) {
    return this.agaveAdapter.get(`finances/transactions/payables-list`, {
      locationId: locationId || this.user.location.id
    })
  }

  getSingleTransaction (params) {
    const {
      transactionId,
      ...others
    } = params
    return this.agaveAdapter.get(`finances/transactions/${transactionId}`, {
      locationId: this.user.location.id,
      ...others
    })
  }

  downloadTransactionPDF (transactionId) {
    return this.agaveAdapter.getBlob(`finances/transactions/${transactionId}/pdf`, {
      locationId: this.user.location.id
    })
  }

  downloadAccountStatment (locationId, params = {}) {
    return this.agaveAdapter.getBlob(`finances/transactions/account-statement`, {
      locationId: (locationId || this.user.location.id),
      ...params
    })
  }

  downloadPaymentSchedule (locationId) {
    return this.agaveAdapter.getBlob(`finances/payment_schedule`, {
      locationId: locationId || this.user.location.id
    })
  }

  getCreditInfo (locationId, params = {}) {
    return this.agaveAdapter.get(`finances/credit_info`, {
      locationId: locationId || this.user.location.id,
      ...params
    })
  }

  payInInstalment ({invoiceId, term, startDate, serviceFee}) {
    return this.agaveAdapter.create('finances/pay_in_instalment', {
      locationId: this.user.location.id,
      invoiceId,
      term,
      startDate,
      serviceFee
    })
  }

  createPaymentPlanOption ({term, orderId, frequency, orderAmount}) {
    return this.agaveAdapter.create('finances/payment-plan-options', {
      locationId: this.user.location.id,
      term,
      orderId,
      frequency,
      orderAmount
    })
  }

  getPaymentPlanOption ({orderId, invoiceTxnId, companyCode}) {
    return this.agaveAdapter.get('finances/payment-plan-options', {
      locationId: this.user.location.id,
      orderId,
      invoiceTxnId,
      companyCode
    })
  }

  getMembershipStatus (locationId, params = {}) {
    return this.agaveAdapter.get('finances/membership_status', {
      locationId: locationId || this.user.location.id,
      ...params
    })
  }
}

module.exports = FinancesApi
