const { Entity } = require('./entity')

class Purchase extends Entity {
  constructor (quickbooks) {
    super(quickbooks, 'Purchase')
  }
}

module.exports = {
  Purchase
}
