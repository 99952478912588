import React from 'react'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { BackButton, Loading } from '@fielded/shared-ui'

import withConfig from '../../../../../van-shared/hoc/withConfig'
import { withApi } from '../../../../../common/ApiProvider'
import { withUser } from '../../../../../common/AuthenticationProvider'
import withShipment from '../../../common/WithShipmentWrapper'
import { usePageLoading } from '../../../../../van-shared/hooks/pageLoading'

import StatementsContainer from '../../../../../statements/StatementsContainer'

const PSMDeliveryNote = ({
  api,
  user,
  config,
  match,
  history,
  shipment: delivery
}) => {
  const { isPageLoading } = usePageLoading()

  if (isPageLoading) {
    return <Loading />
  }

  const locationId = get(delivery, 'destination.id')
  const date = get(delivery, 'snapshotDates.received')

  return (
    <div className='print page'>
      <header className='header--back'>
        <BackButton history={history}>go back</BackButton>
      </header>
      <StatementsContainer
        catchLocation
        locationId={locationId}
        shipment={delivery}
        date={date}
        showDelivery
        isWithLedger={false}
      />
    </div>
  )
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  withShipment
)

export default withHOCs(PSMDeliveryNote)
