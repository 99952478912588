const fields = require('./report-fields-mocks')

const MAX_SORT = 10000

const standardFields = [
  fields['field:standard-beginning-balance'],
  fields['field:standard-received'],
  fields['field:standard-adjusted'],
  fields['field:standard-physical-count'],
  fields['field:standard-remark']
]

const additionalAdjustmentFields = [
  fields['field:standard-lost'],
  fields['field:standard-stock-on-hand-calculated']
]

const negativeAdjustmentFields = [
  fields['field:standard-adjusted-negative'],
  fields['field:standard-stock-on-hand-neg-adjustments-calculated'],
  fields['field:standard-losses-hiv']
]

const standardTBFields = [
  fields['field:standard-opening-balance'],
  fields['field:standard-received'],
  fields['field:standard-consumed-entry'],
  fields['field:standard-losses-adjustments-calculated'], // autocalculated & renamed
  fields['field:standard-closing-calculated'], // closing balance autocalculated
  fields['field:custom-a3mc-calculated'], // add average monthly consumption
  fields['field:standard-physical-inventory'], // renamed physical inventory
  fields['field:custom-a3mc-5-calculated'], //  max stock qty
  fields['field:custom-a3mc-5-diff-calculated'], // qto
  fields['field:standard-remark']
]

const standardEPNFields = [
  fields['field:standard-beginning-balance'],
  Object.assign({}, fields['field:standard-received'], {name: 'Cumulative received'}),
  Object.assign({}, fields['field:standard-consumed-entry'], {name: 'Cumulative dispensed'}),
  fields['field:standard-losses-adjustments-calculated'], // autocalculated & renamed
  fields['field:standard-closing-calculated'], // closing balance autocalculated
  Object.assign({}, fields['field:standard-physical-count'], {name: 'Physical count'}),
  fields['field:standard-remark']
]

const standardShelfLifeFields = [
  fields['field:standard-opening-balance-shelflife'],
  fields['field:standard-physical-count-on-hand-shelflife'],
  fields['field:sold-total-calculated'],
  fields['field:standard-remark'],
  // These are all used in confirmations
  // and stock situation exports
  // but never displayed in report entry
  fields['field:opening-partner-balance'],
  fields['field:opening-shelflife-balance'],
  fields['field:closing-shelflife-balance'],
  fields['field:partner-balance-calculated'],
  fields['field:sold-partner-calculated'],
  fields['field:sold-shelflife-calculated'],
  fields['field:shelflife-partner-buyout'],
  fields['field:shelflife-sales-adjustment']
]

const fieldsConfig = {
  'program:immunization:service:immunization': [
    fields['field:standard-physical-count-immunization']
  ],
  'program:malaria:service:malaria': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-losses-malaria'],
    fields['field:standard-physical-count-on-hand'],
    fields['field:standard-consumed-calculated-negative-adjustment'],
    fields['field:custom-days-out-of-stock'],
    fields['field:standard-remark']
  ],
  'program:malaria:service:ttum': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-losses-malaria'],
    fields['field:standard-physical-count-on-hand'],
    fields['field:standard-consumed-calculated-negative-adjustment'],
    fields['field:custom-days-out-of-stock'],
    fields['field:standard-remark']
  ],
  'program:malaria:service:prevention-routine': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-losses-malaria'],
    fields['field:standard-physical-count-on-hand'],
    fields['field:standard-consumed-calculated-negative-adjustment'],
    fields['field:custom-days-out-of-stock'],
    fields['field:standard-remark']
  ],
  'program:malaria:service:prevention-campaign': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-losses-malaria'],
    fields['field:standard-physical-count-on-hand'],
    fields['field:standard-consumed-calculated-negative-adjustment'],
    fields['field:custom-days-out-of-stock'],
    fields['field:standard-remark']
  ],
  'program:malaria:service:treatment-of-severe-malaria': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-losses-malaria'],
    fields['field:standard-physical-count-on-hand'],
    fields['field:standard-consumed-calculated-negative-adjustment'],
    fields['field:custom-days-out-of-stock'],
    fields['field:standard-remark']
  ],
  'program:family-planning:service:family-planning': [
    fields['field:standard-opening-balance-fp'],
    fields['field:standard-received-fp'],
    fields['field:standard-consumed-entry-fp'],
    fields['field:standard-losses-fp'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-physical-count'],
    fields['field:standard-remark']
  ],
  'program:mnch:service:mnch': [
    fields['field:standard-opening-balance-fp'],
    fields['field:standard-received-fp'],
    Object.assign({}, fields['field:standard-consumed-entry-fp'], {name: 'Quantity dispensed over the past 2 months'}),
    fields['field:standard-losses-fp'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-stock-on-hand-neg-adjustments-calculated'],
    Object.assign({}, fields['field:standard-physical-count'], {name: 'Physical count'}),
    fields['field:standard-remark'],
    fields['field:custom-a2mc-diff-calculated'],
    fields['field:custom-a2mc-calculated']
  ],
  'program:essential-medicines:service:nutrition': [
    fields['field:standard-beginning-balance'],
    fields['field:standard-received'],
    fields['field:standard-consumed-entry'],
    fields['field:standard-losses-fp'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-physical-count'],
    fields['field:standard-remark']
  ],
  'program:hiv-aids:service:pmtct': [
    fields['field:standard-beginning-balance'],
    fields['field:standard-received'],
    fields['field:standard-consumed-entry'],
    fields['field:standard-stock-on-hand-calculated'],
    fields['field:standard-physical-count-pmtct'],
    fields['field:custom-number-of-patients'],
    fields['field:standard-remark-pmtct'],
    fields['field:standard-adjustments-pmtct'],
    fields['field:standard-losses-pmtct']
  ],
  'program:pmtct-scale-up:service:pmtct-scale-up': [
    fields['field:standard-beginning-balance'],
    fields['field:standard-received'],
    fields['field:standard-consumed-entry'],
    fields['field:standard-stock-on-hand-calculated'],
    fields['field:standard-physical-count-pmtct'],
    fields['field:custom-number-of-patients'],
    fields['field:standard-remark-pmtct'],
    fields['field:standard-adjustments-pmtct'],
    fields['field:standard-losses-pmtct']
  ],
  'program:hiv-aids:service:arvs-cotrim': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry']
  ],
  'program:hiv-aids:service:arv-ppr': [
    fields['field:total-custom-number-of-patients'],
    fields['field:custom-patients-in'],
    fields['field:custom-patients-out'],
    fields['field:custom-patients-cumulative']
  ],
  'program:hiv-aids:service:other-ois': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry']
  ],
  'program:hiv-aids:service:rtks-dbs': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:cd4': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:chemistry-selectra': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:chemistry-cobas': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:chemistry-reflotron': [
    ...standardFields,
    ...additionalAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:haematology-abacus': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:haematology-sysmex': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:pcr-abbott': [
    ...standardFields,
    ...additionalAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:pcr-roche': [
    ...standardFields,
    ...additionalAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-a2mc-calculated'],
    fields['field:custom-a2mc-diff-calculated']
  ],
  'program:hiv-aids:service:pcr-roche-6800-8800': [
    ...standardFields,
    ...additionalAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-a2mc-calculated'],
    fields['field:custom-a2mc-diff-calculated']

  ],
  'program:hiv-aids:service:ppr-summary': [
    fields['field:custom-summary']
  ],
  'program:hiv-aids:service:hiv-rapid-test-by-purpose': [
    fields['field:custom-htc'],
    fields['field:custom-pmtct'],
    fields['field:custom-clinical-diagnosis'],
    fields['field:custom-quality-control'],
    fields['field:custom-training'],
    fields['field:custom-screening'],
    fields['field:custom-total']
  ],
  'program:hiv-aids:service:hiv-testing-early-infant-diagnosis': [
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:early-infant-diagnosis-and-viral-load': [
    fields['field:custom-month-1'],
    fields['field:custom-month-2'],
    fields['field:custom-failed-runs']
  ],
  'program:hiv-aids:service:cyflow-others': [
    fields['field:custom-no-of-days']
  ],
  'program:hiv-aids:service:equipment-downtime-cd4': [
    fields['field:custom-no-of-days']
  ],
  'program:hiv-aids:service:equipment-downtime-chemistry': [
    fields['field:custom-model'],
    fields['field:custom-no-of-days']
  ],
  'program:hiv-aids:service:equipment-downtime-haemotology': [
    fields['field:custom-model'],
    fields['field:custom-no-of-days']
  ],
  'program:hiv-aids:service:general-consumables': [
    ...standardFields,
    ...negativeAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done']
  ],
  'program:hiv-aids:service:poc-genexpert': [
    ...standardFields,
    fields['field:standard-lost'],
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-max-stock-lab-calculated'],
    fields['field:custom-qto-calculated']
  ],
  'program:hiv-aids:service:pcr-hologic': [
    ...standardFields,
    fields['field:standard-lost'],
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-max-stock-lab-calculated'],
    fields['field:custom-qto-calculated']
  ],
  'program:hiv-aids:service:pcr-machine-roche-c4800': [
    ...standardFields,
    fields['field:standard-lost'],
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-max-stock-lab-calculated'],
    fields['field:custom-qto-calculated']
  ],
  'program:hiv-aids:service:condom-lubricant': [
    ...standardFields,
    fields['field:standard-losses-hiv'],
    fields['field:standard-adjusted-negative'],
    fields['field:no-distributed'],
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-max-stock-lab-calculated'],
    fields['field:custom-qto-calculated']
  ],
  'program:hiv-aids:service:condom-lubricant-utilization': [
    fields['field:distributed-to-target-group'],
    fields['field:quality-control'],
    fields['field:condom-demonstration'],
    fields['field:advocacy'],
    fields['field:condom-lubricant-utilization-total']
  ],
  'program:hiv-aids:service:early-infant-diagnosis-viral-load-and-hpv': [
    fields['field:custom-month-1'],
    fields['field:custom-month-2'],
    fields['field:failed-runs']
  ],
  'program:hiv-aids:service:early-infant-diagnosis-poc-genexpert': [
    fields['field:custom-month-1'],
    fields['field:custom-month-2'],
    fields['field:failed-runs']
  ],
  'program:hiv-aids:service:early-infant-diagnosis-and-viral-load-pcr-machine-roche-c4800': [
    fields['field:custom-month-1'],
    fields['field:custom-month-2'],
    fields['field:failed-runs']
  ],
  'program:hiv-aids:service:hiv-testing-and-early-infant-diagnosis-and-viral-load-pcr-abbott': [
    fields['field:custom-month-1'],
    fields['field:custom-month-2'],
    fields['field:failed-runs']
  ],
  'program:hiv-aids:service:pcr-roche-c5800-crrf': [
    ...standardFields,
    ...additionalAdjustmentFields,
    fields['field:standard-consumed-entry-lab'],
    fields['field:custom-tests-done'],
    fields['field:custom-a2mc-calculated'],
    fields['field:custom-a2mc-diff-calculated']
  ],
  'program:hiv-aids:service:equipment-downtime-pcr-abbott': [
    fields['field:custom-no-of-days']
  ],
  'program:tb:service:drtb': [
    ...standardTBFields
  ],
  'program:tb:service:dstb': [
    ...standardTBFields
  ],
  'program:tb:service:genexpert-cartridge': [
    ...standardTBFields,
    fields['field:total-positive-test-results'],
    fields['field:total-negative-test-results'],
    fields['field:total-tests-carried-out-calculated']
  ],
  'program:tb:service:presumptive': [
    ...standardTBFields
  ],
  'program:tb:service:laboratory-microscopy': [
    ...standardTBFields,
    fields['field:total-positive-test-results'],
    fields['field:total-negative-test-results'],
    fields['field:total-tests-carried-out-calculated']
  ],
  'program:tb:service:laboratory-reference': [
    ...standardTBFields,
    fields['field:total-positive-test-results'],
    fields['field:total-negative-test-results'],
    fields['field:total-tests-carried-out-calculated']
  ],
  'program:tb:service:drtb-ppr': [
    fields['field:total-custom-number-of-patients'],
    fields['field:custom-patients-in'],
    fields['field:custom-patients-out'],
    fields['field:custom-patients-cumulative-calculated']
  ],
  'program:tb:service:dstb-ppr': [
    fields['field:total-custom-number-of-patients'],
    fields['field:custom-patients-in'],
    fields['field:custom-patients-out'],
    fields['field:custom-patients-cumulative-calculated']
  ],
  'program:tb:service:warehouse': [
    ...standardTBFields
  ],
  'psm-default-fields': [
    fields['field:standard-opening-balance'],
    fields['field:standard-received'],
    fields['field:standard-consumed-entry-lab'],
    fields['field:standard-losses-fp'],
    fields['field:standard-adjusted'],
    fields['field:standard-adjusted-negative'],
    fields['field:standard-stock-on-hand-neg-adjustments-calculated'],
    fields['field:standard-physical-count'],
    fields['field:standard-remark'],
    Object.assign({}, fields['field:custom-a3mc-calculated'], {display: false}),
    fields['field:custom-a3mc-4-calculated'],
    fields['field:custom-a3mc-4-diff-calculated']
  ],
  'program:shelflife:service:pharmaceuticals': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:abuja': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:kenya': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:lagos': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:nasarawa': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:port-harcourt': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:abeokuta': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:lower-east': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:benin': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:ibadan': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:kaduna': [
    ...standardShelfLifeFields
  ],
  'program:new-id-shelflife:service:new-id-test': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:nyanza': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:coastal': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:kano': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:kwara': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:enugu': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:delta': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:south-rift': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:north-rift': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:antara': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:penda': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:medbury': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:cedar': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:demo': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:nasdma': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:reliance': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:rjolad': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:nhsp': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:akwa-ibom': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:osun': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:ekiti': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:ondo': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:central-province': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:katsina': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:mncnh': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:equityafia': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:bauchi': [
    ...standardShelfLifeFields
  ],
  'program:shelflife:service:karen': [
    ...standardShelfLifeFields
  ],
  'program:ke-epn:service:ke-epn': [
    ...standardEPNFields
  ],
  'program:ng-epn:service:ng-epn': [
    ...standardEPNFields
  ]
}

exports.listReportFields = function (state, serviceId) {
  if (typeof serviceId === 'string' && serviceId.includes('-basic-tier')) {
    serviceId = serviceId.split('-basic-tier')[0]
  }
  const fieldList = (fieldsConfig[serviceId] || fieldsConfig['psm-default-fields']).sort(
    function (a, b) {
      return (a.sortIndex || MAX_SORT) - (b.sortIndex || MAX_SORT) // make sure standardFields always comes in first
    })
  return Promise.resolve(fieldList)
}
