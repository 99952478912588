const { bulkSnapshotsFromCsv } = require('./shipment-bulk-snapshot-from-allocation-sheet')
const docIdToShipmentId = require('../../lib/shipment/tools/to-shipment-id')
module.exports = shipmentUpload

async function shipmentUpload (state, {
  funderShipments,
  routes,
  crypto,
  routesApi,
  shipmentApi
}) {
  await routesApi.create({ routeData: routes })

  const allSnapshots = []
  for (const shipment of Object.values(funderShipments)) {
    const snapshots = await bulkSnapshotsFromCsv(state, shipment)
    const firstSnapshot = snapshots[0]

    if (!firstSnapshot.otpKey) {
      const shipmentId = docIdToShipmentId(firstSnapshot.id)
      const otpCode = await shipmentApi.otp.pg.getPassword(shipmentId)
      if (!otpCode && crypto) {
        const snapshotId = firstSnapshot.id
        const { password, key } = await shipmentApi.otp.generate(crypto)
        await shipmentApi.otp.couch.createKeySnapshot(snapshotId, key)
        await shipmentApi.otp.pg.setPassword(shipmentId, password)
      }
    }
    const advanceSnapshots = await shipmentApi.bulkAdvanceSnapshots({ shipments: snapshots })
    allSnapshots.push(...advanceSnapshots)
  }
  return allSnapshots
}
