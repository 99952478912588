import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Page } from '@fielded/shared-ui'

const Complete = ({
  noteName,
  printPathname,
  isAdjustment,
  isEPNFacilityUser
}) => {
  let redirectLink = '/shipments/'
  if (isEPNFacilityUser) {
    redirectLink = `${redirectLink}facility/deliveries`
  }

  const doneButtonProps = {
    component: Link,
    to: redirectLink,
    colorVariant: 'brand',
    fill: 'full'
  }

  return (
    <Page className={isAdjustment ? 'vs-page vs-page--situation-ready vs-page__content' : 'page--confetti'}>
      <Page.Intro
        centered
        title="You're done!"
      />
      <Page.Panel
        withBackground
        narrow
      >
        <Page.Panel.Header title='Delivery saved' />
        <Page.Panel.Section>
          You're all done – the delivery record has been saved.
        </Page.Panel.Section>
        <Page.Panel.Actions>
          <Button
            {...doneButtonProps}
          >
            Finish
          </Button>
          <Button
            component={Link}
            to={printPathname}
            colorVariant='brand'
            fill='outline'
            alignOpposite
          >
            Print {noteName}
          </Button>
        </Page.Panel.Actions>
      </Page.Panel>
    </Page>
  )
}

export default Complete
