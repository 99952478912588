const get = require('lodash/get')

// The SDP thresholds are also applied to state and zonal
// warehouses when calculating levels
const SDP_THRESHOLDS = {
  'program:malaria': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:hiv-aids': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:family-planning': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:mnch': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:tb': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 5 }
  },
  'program:medshelf': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:essential-medicines': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 4 }
  },
  'program:hypertension': {
    min: { months: 1 },
    reOrder: { months: 2 },
    max: { months: 5 }
  },
  // As a first step I am setting EPN thresholds to the warehouse
  // one. This will need to be improved to be able to provide
  // different thresholds for SDPs and warehouses.
  'program:ng-epn': {
    min: { months: 1 },
    reOrder: { months: 1.5 },
    max: { months: 4 }
  },
  'program:ke-epn': {
    min: { months: 1 },
    reOrder: { months: 1.5 },
    max: { months: 4 }
  },
  //  these are applied in the Forecasting Heatmap
  'program:hiv-aids-national-arv': {
    min: { months: 3 },
    reOrder: { months: 6 },
    max: { months: 12 }
  },
  'program:hiv-aids-national-lab': {
    min: { months: 3 },
    reOrder: { months: 5 },
    max: { months: 9 }
  },
  'program:hiv-aids-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:family-planning-national': {
    min: { months: 3 },
    reOrder: { months: 9 },
    max: { months: 18 }
  },
  'program:family-planning-state': {
    min: { months: 2 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:malaria-national': {
    min: { months: 3 },
    reOrder: { months: 5 },
    max: { months: 10 }
  },
  'program:malaria-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:tb-national': {
    min: { months: 3 },
    reOrder: { months: 6 },
    max: { months: 12 }
  },
  'program:tb-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:medshelf-national': {
    min: { months: 3 },
    reOrder: { months: 5 },
    max: { months: 10 }
  },
  'program:medshelf-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:hypertension-national': {
    min: { months: 3 },
    reOrder: { months: 6 },
    max: { months: 12 }
  },
  'program:hypertension-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  },
  'program:essential-medicines-national': {
    min: { months: 3 },
    reOrder: { months: 5 },
    max: { months: 10 }
  },
  'program:essential-medicines-state': {
    min: { months: 1 },
    reOrder: { months: 3 },
    max: { months: 6 }
  }
}

const monthsToDays = months => Math.round(months / 12 * 365)
const daysToMonths = days => Math.round(days / (365 / 12))

function getThresholds (programId) {
  return get(SDP_THRESHOLDS, programId)
}

function getSupplyPlan (programId) {
  const thresholds = getThresholds(programId)
  if (!thresholds) return

  return ({
    supplyPeriodDays: monthsToDays(thresholds.max.months - thresholds.min.months),
    bufferDays: monthsToDays(thresholds.min.months),
    leadTimeDays: monthsToDays(thresholds.reOrder.months - thresholds.min.months)
  })
}

exports.monthsToDays = monthsToDays
exports.daysToMonths = daysToMonths
exports.getThresholds = getThresholds
exports.getSupplyPlan = getSupplyPlan
