class Entity {
  constructor (quickbooks, entityType) {
    this.quickbooks = quickbooks
    this.entityType = entityType
  }

  async get (companyCode, id) {
    const url = `${this.entityType.toLowerCase()}/${id}?minorversion=${this.quickbooks.quickbooksInterface.apiVersion}`
    const {
      [this.entityType]: entity,
      Error: error
    } = await this.quickbooks.quickbooksInterface.get(companyCode, url)
    if (error) {
      throw new Error(error.Message)
    }
    return entity
  }
}

module.exports = {
  Entity
}
