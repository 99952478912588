/* eslint-disable max-lines */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import withOfflineState from '@fielded/shared-ui/src/common/offline'
import { C_R } from '@fielded/fs-api/lib/shipment/tools/planning-types'
import { getShipmentBatches, transformToReturnBatch } from '@fielded/fs-api/lib/shipment/utils/utils'

import withConfig from '../../../van-shared/hoc/withConfig'
import { hasFeature } from '../../../van-shared/utils/features'
import { loadPickList } from '../../../root/reducers/shipments/pick-list'

import { withUser } from '../../../common/AuthenticationProvider'
import { withApi } from '../../../common/ApiProvider'
import withShipment from './../common/WithShipmentWrapper'
import withMasterData from './../common/withMasterData'
import { isShelflifePowered, isArrival, isFpUser, isDistribution, isEPNWarehouse } from '../common/utils'
import { isPsm } from '../../reports/common/utils'

import ShipmentOnlineError from '../common/ShipmentOnlineError'
import Confirmation from './Confirmation'
import ConfirmationOfflineWarning from './ConfirmationOfflineWarning'
import ConfirmationSummary from './ConfirmationSummary'
import SLDeliveryConfirmationContainer from './sl-delivery-confirmation/SLDeliveryConfirmationContainer'

import { createSnapshot } from './confirmation-reducer'

import {
  uncheckedBatches,
  pickListWithName,
  hasCountingDiscrepancies,
  decoratedBatchesByChecked
} from './selectors'

import { checkForPatientGroup } from '../../orders/common/helpers'
import PSMDeliveryConfirmationContainer from './psm-delivery-confirmation/PSMDeliveryConfirmationContainer'
import { updateCurrentMasterData } from '../../../van-shared/utils/utils'

const formRef = React.createRef()

class ConfirmationContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showOfflineWarning: false,
      showTotalAmountConfirmation: false
    }
  }

  componentDidMount () {
    const { config } = this.props
    const useProductTranslation = hasFeature(config, 'features.shipments.useProductTranslation')

    this.setState({ useProductTranslation })
    this.reloadPickList()
  }

  shouldComponentUpdate (props) {
    // Original shipment removed from store before page has transitioned
    return !!props.shipment
  }

  reloadPickList () {
    const {loadPickList, shipment, products} = this.props
    loadPickList(shipment, products)
  }

  componentDidUpdate (prevProps) {
    if (this.props.shipment && !prevProps.shipment) {
      this.reloadPickList()
    }
  }

  openTotalAmountConfirmation = () => {
    this.setState({ showTotalAmountConfirmation: true })
  }

  closeTotalAmountConfirmation = () => {
    this.setState({ showTotalAmountConfirmation: false })
  }

  getNewSnapshotStatus = () => {
    const {
      user,
      shipment
    } = this.props
    // Fp's only see shipments with status new when they're returns
    // So we send them to status sent to be confirmed by the operators
    let status = 'received'
    if (
      ((isFpUser(user) && shipment.status === 'new') ||
       (isFpUser(user) && shipment.planningType === C_R) ||
       shipment.statusType === 'distribution') &&
      !this.isEPNSelfPickup()
    ) {
      // NOTE: this is where the status is set
      status = 'sent'
    }
    return status
  }

  handleProceedClick = () => {
    const {
      isOnline
    } = this.props
    if (isOnline) {
      this.setState({ showOfflineWarning: false })
      this.makeSnapshot()
    } else {
      this.setState({ showOfflineWarning: true })
    }
  }

  handleOfflineClick = () => {
    return this.makeSnapshot()
  }

  isEPNSelfPickup = () => {
    const { shipment, user, config } = this.props
    return isDistribution(shipment.statusType) && isEPNWarehouse({user, config})
  }

  makeSnapshot = async () => {
    const {
      history,
      shipment,
      createSnapshot,
      match: { params: { snapshotId } },
      config,
      api,
      productsById,
      isAdjustment,
      hasCountingDiscrepancies
    } = this.props

    const { useProductTranslation } = this.state

    // We need to check that the snapshot hasn't yet been created
    // for received arrival shipments, since it's the last cycle for shipment,
    // or there would be a PouchDB conflict error when going back from next page
    // and attempting to "Proceed" again
    if (!((isArrival(shipment.statusType) || this.isEPNSelfPickup()) && shipment.status === 'received')) {
      let opts = {
        syncWithAllocation: hasFeature(config.features, 'shipments.addProduct.syncWithAllocation'),
        status: this.getNewSnapshotStatus()
      }

      // Sometimes arrived snapshots are not created by SL drivers (FPs)
      // So we translate here as well just incase
      if (opts.status === 'received' && useProductTranslation) {
        const allProducts = await api.product.listAll()
        const date = new Date().toJSON()
        const destinationId = get(shipment, 'destination.id', '')
        const location = await api.location.get(destinationId, date)
        const { translatedCounts } = await api.shipment.bulkTranslateShipmentProducts({shipment, location, products: allProducts})
        shipment.counts = translatedCounts
      }

      // If it's a patient delivery set the patient name and ID as comments
      const isPatientDelivery = checkForPatientGroup(shipment)

      const comment = isPatientDelivery
        ? `PatientID: ${shipment.patientId}, Patient Name: ${shipment.patientName}`
        : this.state.comment

      if (comment) {
        opts.comment = comment
      }

      if (formRef && formRef.current) {
        const { signature, name } = formRef.current.getFields()
        opts.signature = signature
        opts.name = name
      }

      const batches = getShipmentBatches(shipment, productsById)
      const shouldReturnProducts = transformToReturnBatch(shipment, batches)
      if (shouldReturnProducts.length) {
        shipment.shouldReturnProducts = shouldReturnProducts
      }

      // This is the old VAN code path:
      let snapshot = null
      try {
        snapshot = await createSnapshot(opts, shipment)
      } catch (error) {
        // this would only happen to online offline users
        // editing shipments outside the sync range
        this.setState({ error: error })
      }

      const shipmentIsSigned = shipment.comments.find(
        (comment) => comment.signature
      )
      if (!shipmentIsSigned) {
        try {
          await api.shipment.addComment(snapshotId, null, opts.signature, opts.name)
        } catch (error) {
          this.setState({ error: error })
        }
      }

      // if there is return products navigating user to return shipment flow
      if (snapshot && shouldReturnProducts.length && isShelflifePowered(config) && opts.status === 'received') {
        return history.push(`/shipments/return-list/${snapshot.snapshotId}`)
      }
    }
    if (isAdjustment) {
      try {
        await api.shipment.adjustment.submit(shipment.id)
      } catch (error) {
        // this would only happen to online offline users
        // editing shipments outside the sync range
        this.setState({ error: error })
      }
    }

    if ((hasCountingDiscrepancies || isShelflifePowered(config)) && shipment.statusType === 'arrival') {
      try {
        const params = {
          status: this.getNewSnapshotStatus(),
          comment: this.state.comment
        }
        await createSnapshot(params, shipment)
      } catch (error) {
        console.error(`create snapshot issue`, error)
        this.setState({ error: error })
      }
    }

    history.push(`/shipments/complete/${snapshotId}`)
  }

  onCommentChange = ({target: { value: comment }}) => {
    this.setState({comment})
  }

  render () {
    const {
      batches,
      history,
      pickListWithName,
      shipment,
      uncheckedBatches,
      isOnline,
      config,
      isAdjustment,
      api,
      user
    } = this.props

    const {
      error,
      showTotalAmountConfirmation,
      comment
    } = this.state

    const showDeliveryNote = hasFeature(config.features, 'shipments:showDeliveryNote') && (
      shipment.statusType === 'arrival'
    )

    const showDeliveryNoteSL = showDeliveryNote && isShelflifePowered(config)
    const showDeliveryNotePSM = (showDeliveryNote && isPsm(config)) || this.isEPNSelfPickup()

    const isIncomplete = Object.keys(uncheckedBatches).length > 0

    let situation = 'ready'
    if (isIncomplete) {
      situation = 'caution'
    }
    if (!isOnline) {
      situation = 'caution'
    }

    if (!isOnline && this.state.showOfflineWarning) {
      return (
        <ConfirmationOfflineWarning
          history={history}
          onProceedClick={this.handleOfflineClick}
        />
      )
    }

    if (showDeliveryNoteSL && !isAdjustment) {
      // SL Shipment, normal flow
      return (
        <Confirmation
          history={history}
          hasDeliveryNote={showDeliveryNoteSL}
          situation={situation}
          hasCustomFooter
        >
          {error && (
            <ShipmentOnlineError error={error} />
          )}
          <SLDeliveryConfirmationContainer
            shipment={shipment}
            formRef={formRef}
            config={config}
            showTotalAmountConfirmation={showTotalAmountConfirmation}
            comment={comment}
            onCommentChange={this.onCommentChange}
            onProceedClick={this.handleProceedClick}
            onOpenModal={this.openTotalAmountConfirmation}
            onCloseModal={this.closeTotalAmountConfirmation}
          />
        </Confirmation>
      )
    }

    if (showDeliveryNoteSL && isAdjustment) {
      // SL Adjustment of Completed Shipment
      return (
        <Confirmation
          history={history}
          onProceedClick={this.handleProceedClick}
          hasDeliveryNote={showDeliveryNoteSL}
          situation={situation}
        >
          {error && (
            <ShipmentOnlineError error={error} />
          )}
          <ConfirmationSummary
            batches={batches}
            pickListWithName={pickListWithName}
            shipment={shipment}
            isIncomplete={isIncomplete}
            title={'Confirm update to delivery'}
            diffMessage={'Adjusted to {moreless} than counted at time of delivery'}
            config={config}
            hasAdjustments
          />
        </Confirmation>
      )
    }

    if (showDeliveryNotePSM && !isAdjustment) {
      // PSM Shipment, normal flow
      return (
        <Confirmation
          history={history}
          hasDeliveryNote={showDeliveryNotePSM}
          situation={situation}
          hasCustomFooter
        >
          {error && (
            <ShipmentOnlineError error={error} />
          )}

          <PSMDeliveryConfirmationContainer
            api={api}
            user={user}
            config={config}
            shipment={shipment}
            formRef={formRef}
            comment={comment}
            onCommentChange={this.onCommentChange}
            onProceedClick={this.handleProceedClick}
          />
        </Confirmation>
      )
    }

    return (
      <Confirmation
        history={history}
        onProceedClick={this.handleProceedClick}
        hasDeliveryNote={showDeliveryNote}
        situation={situation}
      >
        {error && (
          <ShipmentOnlineError error={error} />
        )}
      </Confirmation>
    )
  }
}

const mapStateToProps = (state, { config, shipment, match: { params } }) => {
  const newState = updateCurrentMasterData(state, 'masterDataShipments')

  const {products} = newState.masterData
  const productsById = products.byId
  return {
    batches: decoratedBatchesByChecked(newState, params.snapshotId),
    pickListWithName: pickListWithName(newState),
    uncheckedBatches: uncheckedBatches(newState.batches, newState.pickList),
    hasCountingDiscrepancies: hasCountingDiscrepancies(newState.pickList),
    isAdjustment: hasFeature(config.features, 'shipments.adjustments') && shipment.status === 'received',
    shipment,
    products,
    productsById
  }
}

const mapDispatchToProps = {
  createSnapshot,
  loadPickList
}

export default withApi(
  withUser(
    withConfig(
      withMasterData(
        withShipment(
          withOfflineState(
            connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer)
          ),
          { pendingAdjustments: true }
        )
      )
    )
  )
)
