/* eslint-disable max-lines */
/* global Event */
import React, { createRef, Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import flowRight from 'lodash/flowRight'
import get from 'lodash/get'

import { shouldTrackBatches } from '@fielded/fs-api/lib/tools'

import { hasFeature } from '../../../van-shared/utils/features'
import withConfig from '../../../van-shared/hoc/withConfig'
import { withApi } from '../../../common/ApiProvider'
import { withUser } from '../../../common/AuthenticationProvider'
import { userIsAuthorised } from '../../../van-shared/utils/auth'

import withReport from './withReport'
import ReportEntry from './ReportEntry'
import StockUpdateNotification from './StockUpdateNotification'
import {
  submitReport,
  loadProduct,
  updateProduct,
  setDisabledProduct,
  saveOngoingEntry,
  discardOngoingProductEntry,
  checkOutdatedStockReport,
  byProductLine,
  byProductLineSL
} from '../../../root/reducers/reports/report-entry'
import { sortProductByIndices, sortProductSL, isShelflifePowered } from '../common/utils'

import {
  buildOpenReportUrl,
  buildConfirmationUrl,
  buildLocationsUrl,
  buildLedgerUrl,
  buildAddProductsUrl
} from '../app/routes-urls'
import { isPsm, updateCurrentMasterData } from '../../../van-shared/utils/utils'
import { toast } from '@fielded/shared-ui/src/components/Page'

const reportEntryWrapperRef = createRef()
const reportEntryHeaderRef = createRef()

class ReportEntryContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      isOutdated: false,
      productIds: hasFeature(props.config.features, 'stockCount:singleProductLine')
        ? sortProductSL(props.service.id, props.report, props.productsById)
        : sortProductByIndices(props.service.id, props.report, props.productsById),
      alertsRedirect: {
        isFromAlerts: new URLSearchParams(window.location.search).get('alerts')
      }
    }
  }

  componentDidMount () {
    window.dispatchEvent(new Event('van-hide-nav'))
    const {
      loadProduct,
      productId,
      report,
      hasReadOnlyReports,
      history
    } = this.props

    if (hasReadOnlyReports) {
      const confirmationUrl = buildConfirmationUrl({reportId: report._id})
      history.push(confirmationUrl)
    }

    if (productId !== 'any') {
      loadProduct(productId)
    }

    this.isReportOutdated()
  }

  componentDidUpdate () {
    const {
      loadProduct,
      productId
    } = this.props
    loadProduct(productId)
  }

  componentWillUnmount () {
    this._canceled = true
    window.dispatchEvent(new Event('van-show-nav'))
  }

  handleSave () {
    this.setState({error: null})
    const {
      history,
      period,
      programId,
      user,
      saveOngoingEntry,
      service,
      fields
    } = this.props
    saveOngoingEntry({saveAndExit: true}, service, fields.byId)
      .then(() => {
        history.push(buildLocationsUrl({locationId: user.location.id, date: period.entryStartDate, programId}))
      })
  }

  handleRemoteSave () {
    if (this._loading) {
      return false
    }

    this.setState({error: null})

    const {
      history,
      period,
      programId,
      user,
      saveOngoingEntry,
      service,
      config,
      submitReport,
      fields
    } = this.props

    saveOngoingEntry({}, service, fields.byId)
      .then(() => {
        const opensExistingReport = hasFeature(config.features, 'stockCount:opensExistingReport')
        try {
          this._loading = true
          submitReport({opensExistingReport})
            .then(() => {
              toast({
                title: 'Updates got successfully saved',
                type: 'success',
                autoDismiss: 5000
              })
              history.push(buildLocationsUrl({locationId: user.location.id, date: period.entryStartDate, programId}))
            })
        } catch (e) {
          this._loading = false
          toast({
            title: 'Could not save updates',
            type: 'warning',
            autoDismiss: false
          })
          console.error('Error while saving updates', e)
          this.setState({error: e})
        }
      })
  }

  handleGoBack () {
    const {
      history,
      period,
      programId,
      user
    } = this.props
    history.push(buildLocationsUrl({locationId: user.location.id, date: period.entryStartDate, programId}))
  }

  handleSubmit () {
    this.setState({error: null})
    const {
      report,
      history,
      saveOngoingEntry,
      service
    } = this.props

    saveOngoingEntry({ saveAndExit: true }, service)
      .then(() => {
        const confirmationUrl = buildConfirmationUrl({
          reportId: report._id
        })
        history.push(confirmationUrl)
      })
  }

  handleScrollToTop () {
    const { config } = this.props
    if (isPsm(config)) {
      const { clientHeight } = reportEntryHeaderRef.current
      reportEntryWrapperRef.current.scroll({
        top: 0 + clientHeight,
        behavior: 'smooth'
      })
    }
  }

  handleProdNav (diff, replaceHistory) {
    this.setState({error: null})
    const {
      productId,
      history,
      baseSlug,
      saveOngoingEntry,
      service,
      fields
    } = this.props

    const { productIds } = this.state

    const currentIndex = productIds.indexOf(productId)
    const newProductId = productIds[currentIndex + diff]
    saveOngoingEntry(undefined, service, fields.byId)
    if (replaceHistory) {
      history.replace(`${baseSlug}/${newProductId}`)
    } else {
      history.push(`${baseSlug}/${newProductId}`)
      this.handleScrollToTop()
    }
  }

  async handleAddProducts () {
    const {
      report,
      history,
      saveOngoingEntry,
      service,
      fields
    } = this.props
    await saveOngoingEntry({ saveAndExit: true }, service, fields.byId)
    history.push(buildAddProductsUrl({reportId: report._id}))
  }

  handleClickLedgerLink () {
    const {
      location,
      productId,
      service,
      history,
      saveOngoingEntry,
      fields
    } = this.props
    const ledgerUrl = buildLedgerUrl({locationId: location._id, serviceId: service.id, productId})

    saveOngoingEntry({ saveAndExit: true }, service, fields.byId)
      .then(() => {
        history.push(ledgerUrl)
      })
  }

  isReportOutdated () {
    const { report, service } = this.props
    this.props.checkOutdatedStockReport(report, service)
      .then(isOutdated => {
        // do this to avoid warning about setting state on onmounted component
        if (this._canceled) {
          return
        }
        this.setState({ isOutdated })
      })
  }

  async deleteLocalDraft () {
    const { service, report, period, api } = this.props
    const draft = await api.report.draft.find({ locationId: report.location.id, service, period })
    await api.report.draft.remove({ reportId: draft._id })
    // After deleting the draft, reloading the page will load the submitted report instead
    window.location.reload()
  }

  handleDeclineNotificationChanges () {
    // just hide the notification banner
    this.setState({isOutdated: false})
  }

  handleDisableProduct (value) {
    this.setState({ error: null })
    const {
      setDisabledProduct,
      formContainerProps: { fields },
      programId
    } = this.props
    const errorMessage = `Product quantity is greater than 0, can't set to not in use`
    const productIsDisabled = value === 'false'
    if (programId !== 'program:tb' && productIsDisabled) {
      const productHasQuantity = Object.keys(fields)
        .filter(field => field !== 'field:standard-lost')
        .map(field => {
          return fields[field].amount > 0
        })
      const productIsFinished = productHasQuantity && productHasQuantity.every(quantity => quantity === false)
      return productIsFinished ? setDisabledProduct(productIsDisabled) : this.setState({error: errorMessage})
    }
    setDisabledProduct(productIsDisabled)
  }

  render () {
    const {
      error,
      productIds,
      isOutdated,
      alertsRedirect
    } = this.state
    const {
      report,
      productsById,
      service,
      programName,
      programId,
      serviceName,
      location,
      productId,
      period,
      discardOngoingProductEntry,
      url,
      history,
      config,
      user,
      formContainerProps
    } = this.props
    const { rootHistory } = this.context

    const readOnly = !period.isEditable || service.notActive
    const disableProductsNotInUse = hasFeature(config.features, 'stockCount.disableProductsNotInUse')
    // 'readOnly' disables submit button, readOnlyForm only fields
    const readOnlyForm = readOnly || (formContainerProps.disabledByOption && disableProductsNotInUse)

    const reportCreatedAt = get(report, 'createdAt', '')

    const isRealtimeStock = hasFeature(config.features, 'stockCount:realtime')

    // NOTE: PSM-users and Shelf Life non-admin users should be stopped from entering here
    // However, the overview is 'reset' every step in the report entry form, which removes
    // the isEditablePeriod flag from the overview reducer.
    // At the moment, these users are prevented from
    // editing by not having the edit item in their locations list

    // If we have no productId or the product is 'any' we redirect to the first product
    // but do not redirect at all if location has no products
    if (productIds.length >= 1 && (!productId || productId === 'any')) {
      return <Redirect to={buildOpenReportUrl({reportId: report._id, productId: productIds[0]})} />
    }

    const showAddProduct = userIsAuthorised(user, 'feature:stock-reporting-add-product')

    const products = productIds.map(id => productsById[id])
    const singleProductLine = hasFeature(config.features, 'stockCount:singleProductLine')
    const currentProduct = productsById[productId]
    const currentProductNumber = productIds.indexOf(productId) + 1

    let productLines = []
    if (singleProductLine) {
      productLines = byProductLineSL(report.stock, products, productId)
    } else {
      productLines = byProductLine(report.stock, products, service.id, serviceName, productId)
    }

    alertsRedirect.hasAlertsRole = user && user.roles.includes('feature:qc-alerts')

    const reportingForm = <ReportEntry
      url={url}
      alertsRedirect={alertsRedirect}
      error={error}
      report={report}
      location={location}
      period={period}
      programName={programName || 'Vaccines'}
      programId={programId}
      serviceId={service.id}
      serviceName={serviceName}
      products={products}
      productLines={productLines}
      currentProduct={currentProduct}
      currentProductNumber={currentProductNumber}
      showLocationContact={hasFeature(config.features, 'stockCount:showLocationContact')}
      showAddProduct={showAddProduct}
      history={history}
      rootHistory={rootHistory}
      reportEntryWrapperRef={reportEntryWrapperRef}
      reportEntryHeaderRef={reportEntryHeaderRef}
      discardOngoingProductEntry={discardOngoingProductEntry}
      onClickLedgerLink={isRealtimeStock ? this.handleClickLedgerLink : undefined}
      onClickNext={() => this.handleProdNav(1)}
      onClickPrev={() => this.handleProdNav(-1)}
      onClickLocalSave={() => this.handleSave()}
      onClickRemoteSave={() => this.handleRemoteSave()}
      onClickSubmit={() => this.handleSubmit()}
      onClickAddProducts={() => this.handleAddProducts()}
      onProductNavigation={this.handleProdNav}
      goBack={() => this.handleGoBack()}
      config={config}
      readOnly={readOnly}
      handleDisableProduct={disableProductsNotInUse && this.handleDisableProduct}
      formContainerProps={{
        ...formContainerProps,
        disableForm: readOnlyForm,
        updateProduct: this.props.updateProduct
      }}
    />

    const stockUpdateBanner = <Fragment>
      {/* this banner is PSM only */}
      { !isRealtimeStock && isOutdated && (
        <StockUpdateNotification
          message='There has been a submitted version since this report started'
          createdAt={reportCreatedAt}
          acceptLabel='View submitted report'
          onAccept={this.deleteLocalDraft}
          onDecline={this.handleDeclineNotificationChanges}
        />
      )}
    </Fragment>

    return (
      <Fragment>
        { !isShelflifePowered(config) && stockUpdateBanner }
        { reportingForm }
      </Fragment>
    )
  }
}

ReportEntryContainer.propTypes = {
  report: PropTypes.object.isRequired,
  productsById: PropTypes.object.isRequired,
  programName: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  productId: PropTypes.string,
  url: PropTypes.string.isRequired,
  baseSlug: PropTypes.string.isRequired,
  loadProduct: PropTypes.func.isRequired,
  saveOngoingEntry: PropTypes.func.isRequired,
  discardOngoingProductEntry: PropTypes.func.isRequired,
  period: PropTypes.object.isRequired
}

const mapStateToFormContainerProps = (
  { masterData, reportEntry },
  { match, config }
) => {
  const report = reportEntry.data.report
  let fieldName = 'fields'
  let countType = 'standard'
  let presentation

  const editOpeningBalance = hasFeature(config.features, 'stockCount.editOpeningBalance')
  const dislayOpeningQuantityIssue = hasFeature(config.features, 'stockCount.dislayOpeningQuantityIssue')
  const productId = reportEntry.data.ongoingEntry.productId
  if (productId) {
    const product = masterData.products.byId[productId]
    countType = shouldTrackBatches({
      service: { id: masterData.service.id },
      location: report.location
    }) ? 'batches' : countType
    // the amount of units in a batch, needed for validation
    presentation = product.presentation

    if (countType === 'batches') {
      fieldName = 'batches'
    }
  }

  const alerts = reportEntry.alerts || []
  const alertsForProduct = alerts.filter(a => a.target.productId === productId)

  const productReportBalance = get(reportEntry.data.report, `stock.${productId}`, {fields: {}})

  return {
    alerts: alertsForProduct,
    report: reportEntry.data.report,
    fields: reportEntry.data.ongoingEntry[fieldName],
    productReportBalance,
    productId,
    fieldsById: fieldName && masterData[fieldName].byId,
    batchesById: masterData.batches.byId,
    orderedFields: (fieldName && masterData[fieldName].allIds) || [],
    commits: reportEntry.data.ongoingEntry.commits,
    expiry: reportEntry.data.ongoingEntry.expiry,
    countType,
    presentation,
    serviceId: reportEntry.data.report.serviceId,
    url: match.url,
    editOpeningBalance,
    config,
    masterData,
    dislayOpeningQuantityIssue,
    disabledByOption: reportEntry.data.ongoingEntry.disabled
  }
}

const mapStateToProps = (
  state,
  ownProps
) => {
  const newState = updateCurrentMasterData(state, 'masterDataReports')

  const { masterData, reportEntry } = newState
  const { match } = ownProps
  const productId = match.params.productId
  const service = get(masterData, 'service', {})
  const fields = get(masterData, 'fields', {})
  const report = get(reportEntry, 'data.report', {})
  const period = get(reportEntry, 'data.period', {})
  const hasReadOnlyReports = get(reportEntry, 'data.hasReadOnlyReports', false)

  const locationId = get(report, 'location.id')
  const productsById = get(masterData, 'products.byId', {})
  const locationDef = get(masterData, `locations.byId.${locationId}`, {})

  const programId = get(masterData, 'service.program.id', '')

  let formContainerProps
  if (report) {
    formContainerProps = mapStateToFormContainerProps(newState, ownProps)
  }

  return {
    report,
    productsById,
    service,
    programId,
    programName: get(masterData, 'service.program.name', ''),
    serviceName: get(masterData, 'service.name', ''),
    location: locationDef,
    productId,
    url: match.url,
    baseSlug: buildOpenReportUrl({reportId: report._id}),
    period,
    fields,
    hasReadOnlyReports,
    formContainerProps
  }
}

const mapDispatchToProps = {
  loadProduct,
  updateProduct,
  saveOngoingEntry,
  discardOngoingProductEntry,
  checkOutdatedStockReport,
  setDisabledProduct,
  submitReport
}

const withHOCs = flowRight(
  withApi,
  withUser,
  withConfig,
  withReport,
  connect(mapStateToProps, mapDispatchToProps)
)

export default withHOCs(ReportEntryContainer)
