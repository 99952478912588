const getSnapshotId = require('./tools/get-snapshot-id')
const toDocIdProperties = require('./tools/to-doc-id-properties')
const toLocationProperties = require('./tools/to-location-properties')
const otp = require('../otp.js')

/**
 * @typedef {import("../otp").Key} Key
 * @typedef {import("../otp").Config} Config
 */

const UserFailReason = {
  noPassword: 'noPassword',
  noMatch: 'noMatch'
}

/** Create and verify passwords for couch shipments
 *
 * Shipments can contain derived password keys. The key can be compared
 * to a password for verification. The password cannot be guessed from
 * the key without brute force attacking the key. The key is not very
 * secure, but rather supposed to make attacking it inconvenient. The
 * key is attached to the shipment, in order to make verification work
 * offline. The password will be send via another channel.
 *
 * The verification in the frontend works like
 * ```
 * const key = shipment.otp.key
 * const password = <some user input>
 * const verified = api.shipment.otp.verify(window.crypto, password, key)
 * // Add the password to the shipment after verification, so that
 * // we can check that the correct password was actually provided.
 * await api.shipment.otp.couch.createPasswordSnapshot (snapshotId, password)
 * ```
 *
 * The key gets generated and added to the shipment via some backend service
 * ```
 * const crypto = require('node:crypto').webcrypto
 * const { password, key } = await api.shipment.otp.generate(crypto)
 * await api.shipment.otp.couch.createKeySnapshot(snapshotId, key)
 * // Then store/send the password to the appropriate user, so that
 * // they can later hand it over or input it themselves.
 * ```
 *
 */

class OtpCouchApi {
  /**
   * @param {Object} shipmentsDb
   */
  constructor (shipmentsDb) {
    this.shipmentsDb = shipmentsDb
  }

  /**
   * @param {string} snapOrShipId
   * @param {string} snapshotType,
   * @param {Object} data
   * @returns {Promise<{ ok: boolean, id: string, rev: string }>}
   */
  createOtpSnapshot (snapOrShipId, snapshotType, data) {
    const idProps = toDocIdProperties(snapOrShipId)
    const id = getSnapshotId(
      { device: { id: 0 } },
      {
        status: idProps.status,
        shipmentNo: idProps.shipmentNo,
        date: idProps.date,
        origin: toLocationProperties(idProps.origin),
        destination: toLocationProperties(idProps.destination)
      }
    ) + `:otp:${snapshotType}`

    const snapshot = {
      _id: id,
      type: 'otp',
      createdAt: new Date().toJSON(),
      ...data
    }

    return this.shipmentsDb.put(snapshot)
  }

  /**
   * @param {string} snapOrShipId
   * @param {string} key,
   * @returns {Promise<{ ok: boolean, id: string, rev: string }>}
   */
  createKeySnapshot (snapOrShipId, key) {
    return this.createOtpSnapshot(snapOrShipId, 'key', { otpKey: key })
  }

  /**
   * @param {string} snapOrShipId
   * @param {string} password,
   * @returns {Promise<{ ok: boolean, id: string, rev: string }>}
   */
  createPasswordSnapshot (snapOrShipId, password) {
    this.createOtpSnapshot(snapOrShipId, 'password', { otpPassword: password })
  }

  /**
   * @param {string} snapOrShipId
   * @param {string} reason,
   * @returns {Promise<{ ok: boolean, id: string, rev: string }>}
   */
  createFailSnapshot (snapOrShipId, reason) {
    this.createOtpSnapshot(snapOrShipId, 'fail', { otpUserFailReason: reason })
  }
}

class OtpRestApi {
  /**
   * @param {Object} agaveAdapter
   */
  constructor (agaveAdapter) {
    this.agaveAdapter = agaveAdapter
  }

  /** Get password for shipment
   *
   * @param {string} shipmentId
   * @returns {Promise<{ password: string } | null>}
   */
  getPassword (shipmentId) {
    return this.agaveAdapter.get(`shipment/otp/${shipmentId}`)
  }
}

class OtpPgApi {
  /**
   * @param {Object} pgPool
   */
  constructor (pgPool) {
    this.pgPool = pgPool
  }

  /** Get password for shipment
   *
   * @param {string} shipmentId
   * @returns {Promise<string | null>}
   */
  async getPassword (shipmentId) {
    const { rows } = await this.pgPool.query(`
      select password from avocado.shipment_otp
      where shipment_id = $1
    `, [shipmentId])
    if (rows.length === 0) {
      return null
    }
    return rows[0].password
  }

  async setPassword (shipmentId, password) {
    await this.pgPool.query(`
      insert into avocado.shipment_otp (shipment_id, password)
      values ($1, $2)
    `, [shipmentId, password])
  }
}

class OtpApi {
  constructor ({ shipmentsDb, agaveAdapter, pgConnection }) {
    this.couch = new OtpCouchApi(shipmentsDb)
    this.rest = new OtpRestApi(agaveAdapter)
    this.pg = new OtpPgApi(pgConnection)
  }

  /** Get the otp key version config
   *
   * @param {Key} otpKey
   * @returns {Config}
   */
  getConfig (otpKey) {
    return otp.getConfig(otpKey.version)
  }

  /** Generate a password and key
   *
   * @param {Crypto} crypto
   * @returns {Promise<{ password: string, key: Key }>}
   */
  generate (crypto, version = 'v1') {
    return otp.generate(crypto, version)
  }

  /** Verify password matches key in shipment
   *
   * @param {Crypto} crypto
   * @param {string} password
   * @param {Key} key
   * @returns {Promise<boolean | null>}
   */
  verify (crypto, password, key) {
    return otp.verify(crypto, password, key)
  }
}

module.exports = {
  OtpApi,
  UserFailReason
}
